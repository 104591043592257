import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { NoDecimalPipe } from './../../_pipes/no-decimal.pipe';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions, StripeCardElementChangeEvent, PaymentIntent } from '@stripe/stripe-js';

@Component({
    selector: 'app-stripe-pay-button',
    templateUrl: './stripe-pay-button.component.html',
    styleUrls: ['./stripe-pay-button.component.scss']
})
export class StripePayButtonComponent implements OnInit {
    @Input() charge;
    @Input() clientInfo;
    @Input() id;
    @Output() eventListener = new EventEmitter();
    @ViewChild(StripeCardComponent) card: StripeCardComponent;

    public loading = false;
    // private _paymentSuccess: boolean = false;

    public cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                // backgroundColor: '#028ee1',
                color: 'white',
                fontWeight: '400',
                fontFamily: '"Alegreya", "Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#999',
                },
                iconColor: '#fff',
            },
        },
    };
    public elementsOptions: StripeElementsOptions = {
        locale: 'en',
    };
    private privateKey: string = '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'; // erich@scheppfamily.com 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 // TJNevis@gmail.com - sk_test_zJ7MYjtW5rxuhDqY9bXCNjui | rick@harrisfuneralhome.com - sk_test_51Hjn8wA7zjkr2lYuM1lfesGCKm4cwNY2aDBIL7mYAHPWKSgY5DJegi9fJ9vX4nIAnPIwy8NWVReTt9JIOao7CLT30085a5WrlW
    private stripeTest: FormGroup;



    constructor(
        private HttpClient: HttpClient,
        private FormBuilder: FormBuilder,
        private StripeService: StripeService,
        private NoDecimalPipe: NoDecimalPipe,
    ) { }



    public createPaymentIntent(amount: number): Observable<any> { //: Observable<PaymentIntent> {
        return Observable.create(observer => {
            observer.next(true);
        });
        // return this.HttpClient.post<PaymentIntent>(
        //     ''
        // );
        // return this.http.post<PaymentIntent>(
        //     `${env.apiUrl}/create-payment-intent`,
        //     { amount }
        // );
    }



    public createToken(): void {
        this.loading = true;

        this.StripeService
            .createToken(this.card.element, { name: this.clientInfo.name }) // Creating the token (ngx-stipe) didn't like me sending email and phone
            .subscribe((result) => {
                if (result.token) {
                    this.charge = this.NoDecimalPipe.transform((this.charge * 100)); // Stripe doesn't want the decimal

                    this.HttpClient.post('/payment/submit', { charge: this.charge, client: this.clientInfo, id: this.id, token: result.token }).subscribe((response: any) => {
                        this.loading = false;

                        if (response.stripe && response.stripe.paid) // We can assume it has gone successfully
                            this.eventListener.emit({ paymentSuccess: true })
                        else
                            this.eventListener.emit({ paymentSuccess: false })
                    });

                } else if (result.error) {
                    this.loading = false;
                    // Error creating the token
                    const cardErrors = document.getElementById('card-errors');
                    cardErrors.textContent = (result.error) ? result.error.message : '';
                    console.log(result.error.message);
                }
            });
    }




    public onChange(e: StripeCardElementChangeEvent) {
        const cardErrors = document.getElementById('card-errors');
        cardErrors.textContent = (e.error) ? e.error.message : '';
    }



    public pay(): void {
        if (this.stripeTest.valid) {
            this.createPaymentIntent(this.stripeTest.get('amount').value)
                .pipe(
                    switchMap((pi) =>
                        this.StripeService.confirmCardPayment(this.privateKey, {
                            payment_method: {
                                card: this.card.element,
                                // meta_data: {
                                //     order_id: 456,
                                // }
                                // billing_details: {
                                //     email: 'tjnevis@gmail.com',
                                //     name: 'Test Client',
                                //     order: 123,
                                //     phone: '5853057627',
                                // },
                            },
                        })
                    )
                )
                .subscribe((result) => {
                    if (result.error) {
                        // Show error to your customer (e.g., insufficient funds)
                        console.log(result.error.message);
                    } else {
                        // The payment has been processed!
                        if (result.paymentIntent.status === 'succeeded') {
                            // Show a success message to your customer
                        }
                    }
                });
        } else {
            console.log(this.stripeTest);
        }
    }



    ngOnInit(): void {
        // https://stackoverflow.com/questions/46039315/how-can-i-style-a-stripe-elements-input-with-bootstrap

        // this.stripeTest = this.FormBuilder.group({
        //     name: ['', [Validators.required]]
        // });

        // this.stripeTest = this.FormBuilder.group({
        //     name: ['Angular v10', [Validators.required]],
        //     amount: [1000, [Validators.required, Validators.pattern(/\d+/)]],
        // });
    }



}
